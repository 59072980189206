import { IDL } from "@dfinity/candid";
export const idlFactory : IDL.InterfaceFactory = ({ IDL }) => {
  const CanisterHealthStats = IDL.Record({
    'heapSize' : IDL.Nat,
    'cycleBalance' : IDL.Nat,
  });
  const Result_24 = IDL.Variant({
    'ok' : CanisterHealthStats,
    'err' : IDL.Text,
  });
  const Cycles = IDL.Nat;
  const TopupRule__1 = IDL.Record({
    'method' : IDL.Variant({ 'by_amount' : Cycles, 'to_balance' : Cycles }),
    'threshold' : Cycles,
  });
  const TeamID = IDL.Principal;
  const AddCanisterResult = IDL.Variant({
    'err' : IDL.Variant({
      'other' : IDL.Text,
      'already_added_not_verified' : IDL.Null,
      'already_added_and_verified' : IDL.Null,
      'invalid_request' : IDL.Text,
    }),
    'verified' : IDL.Null,
    'notVerified' : IDL.Null,
  });
  const AddCanisterResultVersioned = IDL.Variant({
    'err' : IDL.Variant({
      'other' : IDL.Text,
      'already_added_not_verified' : IDL.Null,
      'already_added_and_verified' : IDL.Null,
      'invalid_request' : IDL.Text,
    }),
    'verified' : IDL.Null,
    'notVerified' : IDL.Null,
  });
  const CMCPurchase = IDL.Record({
    'initialCycleBalance' : IDL.Nat,
    'icpSpent' : IDL.Nat,
    'cyclesPurchased' : IDL.Nat,
    'cmcRate' : IDL.Opt(IDL.Nat),
  });
  const CyclesPurchaseDetail = IDL.Record({
    'newCycleBalance' : IDL.Nat,
    'previousCycleBalance' : IDL.Nat,
    'previousInitialPointCycleRate' : IDL.Nat,
    'newInitialPointCycleRate' : IDL.Nat,
    'icpSpent' : IDL.Nat,
    'timestamp' : IDL.Nat,
    'cyclesPurchased' : IDL.Nat,
    'cmcRate' : IDL.Opt(IDL.Nat),
  });
  const AddNNSCanisterResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : IDL.Text,
  });
  const SubnetId = IDL.Principal;
  const SubnetType = IDL.Variant({
    'application' : IDL.Null,
    '_system' : IDL.Null,
  });
  const CanisterId = IDL.Principal;
  const AddNewLimitedSubnetCanisterHubArgs = IDL.Record({
    'requiresCanisterPresence' : IDL.Bool,
    'subnetId' : SubnetId,
    'specialization' : IDL.Opt(IDL.Text),
    'subnetType' : SubnetType,
    'canisterId' : CanisterId,
  });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const AddSNSMonitoredCanistersResult = IDL.Variant({
    'no_canisters_added' : IDL.Variant({
      'err' : IDL.Text,
      'non_sns_canisters' : IDL.Vec(IDL.Text),
      'monitoring_failure' : IDL.Vec(IDL.Text),
    }),
    'partial_monitoring_failure' : IDL.Vec(IDL.Text),
    'success' : IDL.Null,
  });
  const BlockIndex__1 = IDL.Nat;
  const DepositResult = IDL.Record({
    'balance' : IDL.Nat,
    'block_index' : BlockIndex__1,
  });
  const Result_23 = IDL.Variant({ 'ok' : DepositResult, 'err' : IDL.Text });
  const Tokens = IDL.Record({ 'e8s' : IDL.Nat64 });
  const TopupTransaction = IDL.Record({
    'icpCharged' : Tokens,
    'cyclesToppedUpWith' : IDL.Nat,
    'canisterId' : IDL.Principal,
  });
  const Account = IDL.Record({
    'owner' : IDL.Principal,
    'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
  });
  const CycleLedgerTopupTransaction = IDL.Record({
    'transferFromIndex' : IDL.Nat,
    'cyclesToppedUpWith' : IDL.Nat,
    'cyclesCharged' : IDL.Nat,
    'account' : Account,
    'canisterId' : IDL.Principal,
  });
  const TransactionType = IDL.Variant({
    'topup' : TopupTransaction,
    'cycle_ledger_topup' : CycleLedgerTopupTransaction,
  });
  const CustomerTransaction = IDL.Record({
    'transaction' : TransactionType,
    'timestamp' : IDL.Nat,
    'customerId' : IDL.Principal,
  });
  const SharedCanisterSearchMetadata = IDL.Record({
    'projectName' : IDL.Opt(IDL.Text),
    'owner' : IDL.Principal,
    'isCanisterNameVerified' : IDL.Opt(IDL.Bool),
    'keywordTags' : IDL.Vec(IDL.Text),
    'isPublic' : IDL.Bool,
  });
  const SharedCanisterTagSearchBiMap = IDL.Record({
    'tagToCanisterIds' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Principal))),
    'idToSearchMetadata' : IDL.Vec(
      IDL.Tuple(IDL.Principal, SharedCanisterSearchMetadata)
    ),
  });
  const PerAccountSharedCanisterTagSearchBiMap = IDL.Record({
    'canisterTagSearchBiMap' : SharedCanisterTagSearchBiMap,
    'customerId' : IDL.Principal,
  });
  const NotificationChannel = IDL.Variant({ 'email' : IDL.Null });
  const CustomerNotificationSettings = IDL.Record({
    'notifyOnCyclesApprovalBalanceBelow' : IDL.Opt(IDL.Nat),
    'channels' : IDL.Vec(NotificationChannel),
    'notifyOnICPBelow' : IDL.Opt(Tokens),
    'notifyOnTopupSuccess' : IDL.Bool,
    'notifyOnMemoryThresholdReached' : IDL.Bool,
    'notifyOnTopupFailure' : IDL.Bool,
    'notifyOnReservedCyclesThresholdReached' : IDL.Bool,
  });
  const CustomerMetadata = IDL.Record({
    'username' : IDL.Text,
    'displayName' : IDL.Opt(IDL.Text),
    'description' : IDL.Opt(IDL.Text),
    'website' : IDL.Opt(IDL.Text),
    'logoUrl' : IDL.Opt(IDL.Text),
  });
  const VerificationCode = IDL.Record({ 'code' : IDL.Text });
  const Email = IDL.Text;
  const CustomerEmail__2 = IDL.Record({
    'verified' : IDL.Bool,
    'verificationCode' : IDL.Opt(VerificationCode),
    'address' : Email,
  });
  const Permission__1 = IDL.Variant({
    'admin' : IDL.Null,
    'read' : IDL.Null,
    'write' : IDL.Null,
  });
  const AccountTypeShared__2 = IDL.Variant({
    'team' : IDL.Record({
      'teamType' : IDL.Variant({
        'standard_team' : IDL.Null,
        'sns_team' : IDL.Null,
      }),
      'members' : IDL.Vec(IDL.Tuple(IDL.Principal, Permission__1)),
    }),
    'individual' : IDL.Vec(IDL.Principal),
  });
  const CanisterId__3 = IDL.Principal;
  const Version = IDL.Nat;
  const MonitoringCanisterType__1 = IDL.Variant({
    'nns' : IDL.Null,
    'sns' : IDL.Null,
    'publicStatus' : IDL.Null,
    'blackhole' : Version,
  });
  const CanisterConfig__1 = IDL.Record({
    'id' : CanisterId__3,
    'topupRule' : TopupRule__1,
    'name' : IDL.Text,
    'memoryThreshold' : IDL.Opt(IDL.Nat),
    'createdTimestamp' : IDL.Opt(IDL.Nat),
    'reservedCyclesPercentageThreshold' : IDL.Opt(IDL.Nat),
    'monitoringType' : MonitoringCanisterType__1,
  });
  const PaymentMethod = IDL.Variant({ 'icp' : IDL.Null, 'cycles' : IDL.Null });
  const PaymentConfigurationShared = IDL.Record({
    'paymentMethod' : PaymentMethod,
    'cyclesAccount' : IDL.Opt(
      IDL.Record({
        'approvalAmount' : IDL.Opt(IDL.Nat),
        'walletProvider' : IDL.Variant({
          'plug' : IDL.Null,
          'external' : IDL.Null,
        }),
        'account' : Account,
      })
    ),
  });
  const CustomerRecordShared__2 = IDL.Record({
    'customerNotifications' : CustomerNotificationSettings,
    'metadata' : CustomerMetadata,
    'email' : IDL.Opt(CustomerEmail__2),
    'accountType' : AccountTypeShared__2,
    'onboardingCompletedTimestamp' : IDL.Opt(IDL.Nat),
    'canisters' : IDL.Vec(IDL.Tuple(IDL.Principal, CanisterConfig__1)),
    'createdTimestamp' : IDL.Opt(IDL.Nat),
    'customerId' : IDL.Principal,
    'paymentConfiguration' : PaymentConfigurationShared,
  });
  const AccountNotificationType = IDL.Variant({
    'icp_balance_low' : IDL.Record({
      'currentBalance' : Tokens,
      'alertThreshold' : Tokens,
    }),
    'cycles_ledger_refund_error' : IDL.Record({
      'errorMessage' : IDL.Text,
      'customerId' : IDL.Text,
      'cyclesNotRefunded' : IDL.Nat,
    }),
    'cycles_ledger_approval_balance_low' : IDL.Record({
      'currentApprovalBalance' : IDL.Nat,
      'alertThreshold' : IDL.Nat,
    }),
    'admin_team_error' : IDL.Record({ 'errorMessage' : IDL.Text }),
  });
  const AccountNotificationEvent = IDL.Record({
    'notificationType' : AccountNotificationType,
  });
  const CanisterNotificationType = IDL.Variant({
    'cycles_ledger_topup_failure' : IDL.Record({
      'balance' : IDL.Opt(IDL.Nat),
      'errorMessage' : IDL.Text,
    }),
    'cycles_ledger_withdraw_error' : IDL.Record({
      'cyclesRequested' : IDL.Nat,
      'message' : IDL.Text,
      'cyclesBalance' : IDL.Nat,
    }),
    'cycle_mint_error_notify' : IDL.Record({
      'icpBalance' : IDL.Nat,
      'message' : IDL.Text,
      'icpRequested' : IDL.Nat,
    }),
    'global_topup' : IDL.Record({
      'cycleLedgerFunded' : IDL.Nat,
      'cyclesRequested' : IDL.Nat,
      'cyclesToppedUpWith' : IDL.Nat,
      'icpBurned' : IDL.Nat,
    }),
    'cycles_ledger_insufficient_allowance' : IDL.Record({
      'balance' : IDL.Nat,
      'totalCyclesRequested' : IDL.Nat,
      'cyclesRequestedForCanister' : IDL.Nat,
      'currentAllowance' : IDL.Nat,
    }),
    'not_enough_funds' : IDL.Record({
      'icpAvailable' : Tokens,
      'balance' : IDL.Nat,
      'icpRequested' : Tokens,
    }),
    'memory_threshold_reached' : IDL.Record({
      'memoryThreshold' : IDL.Nat,
      'memorySize' : IDL.Nat,
    }),
    'topup_success' : IDL.Record({
      'balance' : IDL.Nat,
      'cost' : Tokens,
      'cyclesAdded' : IDL.Nat,
    }),
    'cycle_mint_error_icp_transfer' : IDL.Record({
      'icpBalance' : IDL.Nat,
      'message' : IDL.Text,
      'icpRequested' : IDL.Nat,
    }),
    'cycles_ledger_topup_success' : IDL.Record({
      'balance' : IDL.Nat,
      'cost' : IDL.Nat,
      'cyclesAdded' : IDL.Nat,
    }),
    'reserved_cycles_threshold_reached' : IDL.Record({
      'reservedCyclesPercentageThreshold' : IDL.Nat,
      'reservedCyclesLimit' : IDL.Nat,
      'reservedCycles' : IDL.Nat,
    }),
    'partial_topup' : IDL.Record({
      'balance' : IDL.Nat,
      'cost' : Tokens,
      'cyclesRequested' : IDL.Nat,
      'cyclesToppedUpWith' : IDL.Nat,
    }),
    'topup_failure' : IDL.Record({
      'balance' : IDL.Opt(IDL.Nat),
      'errorMessage' : IDL.Text,
    }),
  });
  const CanisterNotificationEvent = IDL.Record({
    'canisterName' : IDL.Text,
    'notificationType' : CanisterNotificationType,
    'canisterId' : IDL.Principal,
  });
  const CustomerNotificationEvent = IDL.Variant({
    'account_notification' : AccountNotificationEvent,
    'canister_notification' : CanisterNotificationEvent,
  });
  const CustomerNotificationChannel = IDL.Variant({ 'email' : IDL.Text });
  const CustomerNotificationMessage = IDL.Record({
    'notifications' : IDL.Vec(CustomerNotificationEvent),
    'topupStartTimestamp' : IDL.Nat,
    'notificationChannels' : IDL.Vec(CustomerNotificationChannel),
    'customerId' : IDL.Principal,
  });
  const Project = IDL.Record({
    'id' : IDL.Text,
    'owner' : IDL.Principal,
    'name' : IDL.Text,
    'createdTimestamp' : IDL.Nat,
  });
  const CMCMetrics = IDL.Record({
    'timestampInMs' : IDL.Nat,
    'totalCMCCyclesMinted' : IDL.Nat,
    'hourlyCMCMintingLimit' : IDL.Nat,
    'cyclesMintedInThePastHour' : IDL.Nat,
    'currentICPXDRConversionRate' : IDL.Opt(IDL.Float64),
  });
  const CanisterId__2 = IDL.Principal;
  const QueryStats = IDL.Record({
    'response_payload_bytes_total' : IDL.Nat,
    'num_instructions_total' : IDL.Nat,
    'num_calls_total' : IDL.Nat,
    'request_payload_bytes_total' : IDL.Nat,
  });
  const SharedSeriesStatusDatum = IDL.Record({
    'memory_size' : IDL.Opt(IDL.Nat),
    'cycles' : IDL.Nat,
    'query_stats' : IDL.Opt(QueryStats),
    'reserved_cycles' : IDL.Opt(IDL.Nat),
  });
  const LogVisibility__1 = IDL.Variant({
    'controllers' : IDL.Null,
    'public' : IDL.Null,
    'allowed_viewers' : IDL.Vec(IDL.Principal),
  });
  const DefiniteCanisterSettings__1 = IDL.Record({
    'freezing_threshold' : IDL.Opt(IDL.Nat),
    'controllers' : IDL.Vec(IDL.Principal),
    'reserved_cycles_limit' : IDL.Opt(IDL.Nat),
    'log_visibility' : IDL.Opt(LogVisibility__1),
    'wasm_memory_limit' : IDL.Opt(IDL.Nat),
    'memory_allocation' : IDL.Opt(IDL.Nat),
    'compute_allocation' : IDL.Opt(IDL.Nat),
  });
  const RecentDPSharedStats = IDL.Record({
    'status' : IDL.Opt(
      IDL.Variant({
        'stopped' : IDL.Null,
        'stopping' : IDL.Null,
        'running' : IDL.Null,
      })
    ),
    'settings' : IDL.Opt(DefiniteCanisterSettings__1),
    'idle_cycles_burned_per_day' : IDL.Opt(IDL.Nat),
    'timestamp' : IDL.Nat,
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'reserved_cycles' : IDL.Opt(IDL.Nat),
  });
  const DashboardReducedSharedStatusHistory = IDL.Record({
    'seriesStatusHistory' : IDL.Vec(
      IDL.Tuple(IDL.Nat, SharedSeriesStatusDatum)
    ),
    'recentStats' : IDL.Opt(RecentDPSharedStats),
  });
  const ReturnableCanisterSearchMetadata = IDL.Record({
    'projectName' : IDL.Opt(IDL.Text),
    'owner' : IDL.Principal,
    'isCanisterNameVerified' : IDL.Opt(IDL.Bool),
    'keywordTags' : IDL.Vec(IDL.Text),
    'isPublic' : IDL.Bool,
  });
  const CustomerCanisterData = IDL.Tuple(
    CanisterId__2,
    CanisterConfig__1,
    DashboardReducedSharedStatusHistory,
    IDL.Opt(ReturnableCanisterSearchMetadata),
    MonitoringCanisterType__1,
  );
  const PaginatedCustomerCanisters = IDL.Record({
    'canisters' : IDL.Vec(CustomerCanisterData),
    'nextKey' : IDL.Opt(IDL.Principal),
  });
  const DefiniteCanisterSettings__2 = IDL.Record({
    'freezing_threshold' : IDL.Nat,
    'controllers' : IDL.Vec(IDL.Principal),
    'memory_allocation' : IDL.Nat,
    'compute_allocation' : IDL.Nat,
  });
  const CanisterStatus = IDL.Record({
    'status' : IDL.Variant({
      'stopped' : IDL.Null,
      'stopping' : IDL.Null,
      'running' : IDL.Null,
    }),
    'memory_size' : IDL.Nat,
    'cycles' : IDL.Nat,
    'settings' : DefiniteCanisterSettings__2,
    'idle_cycles_burned_per_day' : IDL.Nat,
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
  });
  const Result_22 = IDL.Variant({ 'ok' : CanisterStatus, 'err' : IDL.Text });
  const Result_8 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  const CanisterId__5 = IDL.Principal;
  const BatchUpdateCanisterMemoryThreshold = IDL.Record({
    'canisterIds' : IDL.Vec(CanisterId__5),
    'memoryThreshold' : IDL.Opt(IDL.Nat),
  });
  const BatchUpdateCanisterMemoryThresholdResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : CanisterId__5,
  });
  const BatchUpdateReservedCyclesThreshold = IDL.Record({
    'canisterIds' : IDL.Vec(CanisterId__5),
    'reservedCyclesPercentageThreshold' : IDL.Opt(IDL.Nat),
  });
  const BatchUpdateReservedCyclesThresholdResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : CanisterId__5,
  });
  const BatchUpdateTopupRule = IDL.Record({
    'topupRule' : TopupRule__1,
    'canisterIds' : IDL.Vec(CanisterId__5),
  });
  const BatchUpdateTopupRuleResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : CanisterId__5,
  });
  const BatchDeleteCanisterResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : IDL.Principal,
  });
  const ManualTopupType = IDL.Variant({ 'icp' : Tokens, 'cycles' : IDL.Nat });
  const CanisterTopup = IDL.Record({
    'topupAmount' : ManualTopupType,
    'canisterId' : IDL.Principal,
  });
  const BatchUpdateCanisterProjects = IDL.Record({
    'projectName' : IDL.Opt(IDL.Text),
    'canisterIds' : IDL.Vec(IDL.Principal),
  });
  const BatchUpdateCanisterProjectsResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : IDL.Principal,
  });
  const BatchUpdateCanisterSearchMetadata = IDL.Record({
    'canisterIds' : IDL.Vec(IDL.Principal),
    'searchMetadata' : IDL.Record({
      'projectName' : IDL.Opt(IDL.Text),
      'keywordTags' : IDL.Vec(IDL.Text),
      'isPublic' : IDL.Bool,
    }),
  });
  const BatchUpdateCanisterSearchMetadataResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : IDL.Principal,
  });
  const BatchUpdateCanisterTags = IDL.Record({
    'canisterIds' : IDL.Vec(IDL.Principal),
    'keywordTags' : IDL.Vec(IDL.Text),
  });
  const BatchUpdateCanisterTagsResult = IDL.Record({
    'result' : Result_1,
    'canisterId' : IDL.Principal,
  });
  const SubnetSelection = IDL.Variant({
    'Subnet' : IDL.Record({ 'subnet' : IDL.Principal }),
  });
  const Result_20 = IDL.Variant({ 'ok' : IDL.Principal, 'err' : IDL.Text });
  const Result_21 = IDL.Variant({ 'ok' : CustomerMetadata, 'err' : IDL.Text });
  const PublicCanisterStatusWithOptionals__1 = IDL.Record({
    'status' : IDL.Variant({
      'stopped' : IDL.Null,
      'stopping' : IDL.Null,
      'running' : IDL.Null,
    }),
    'memory_size' : IDL.Nat,
    'cycles' : IDL.Nat,
    'settings' : DefiniteCanisterSettings__1,
    'query_stats' : IDL.Opt(QueryStats),
    'idle_cycles_burned_per_day' : IDL.Opt(IDL.Nat),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'reserved_cycles' : IDL.Opt(IDL.Nat),
  });
  const Result_19 = IDL.Variant({
    'ok' : PublicCanisterStatusWithOptionals__1,
    'err' : IDL.Text,
  });
  const FrontendFriendlyPublicCanisterStateV3 = IDL.Variant({
    'publicCanister' : IDL.Record({
      'owner' : IDL.Opt(IDL.Principal),
      'statusHistory' : IDL.Vec(IDL.Tuple(IDL.Nat, Result_19)),
      'monitoringType' : MonitoringCanisterType__1,
      'contributors' : IDL.Vec(IDL.Principal),
      'watchers' : IDL.Vec(IDL.Principal),
    }),
    'verifiedBlackhole' : IDL.Record({
      'contributors' : IDL.Vec(IDL.Principal),
      'watchers' : IDL.Vec(IDL.Principal),
    }),
  });
  const LogVisibility = IDL.Variant({
    'controllers' : IDL.Null,
    'public' : IDL.Null,
    'allowed_viewers' : IDL.Vec(IDL.Principal),
  });
  const CanisterStatusSettings = IDL.Record({
    'freezing_threshold' : IDL.Nat,
    'controllers' : IDL.Vec(IDL.Principal),
    'reserved_cycles_limit' : IDL.Opt(IDL.Nat),
    'log_visibility' : IDL.Opt(LogVisibility),
    'wasm_memory_limit' : IDL.Opt(IDL.Nat),
    'memory_allocation' : IDL.Nat,
    'compute_allocation' : IDL.Nat,
  });
  const CanisterStatusWithOptionals = IDL.Record({
    'status' : IDL.Variant({
      'stopped' : IDL.Null,
      'stopping' : IDL.Null,
      'running' : IDL.Null,
    }),
    'memory_size' : IDL.Nat,
    'cycles' : IDL.Nat,
    'settings' : CanisterStatusSettings,
    'query_stats' : IDL.Opt(
      IDL.Record({
        'response_payload_bytes_total' : IDL.Nat,
        'num_instructions_total' : IDL.Nat,
        'num_calls_total' : IDL.Nat,
        'request_payload_bytes_total' : IDL.Nat,
      })
    ),
    'idle_cycles_burned_per_day' : IDL.Nat,
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'reserved_cycles' : IDL.Opt(IDL.Nat),
  });
  const Result_17 = IDL.Variant({
    'ok' : CanisterStatusWithOptionals,
    'err' : IDL.Text,
  });
  const FrontendFriendlyCanisterStateV3 = IDL.Record({
    'owner' : IDL.Principal,
    'statusHistory' : IDL.Vec(IDL.Tuple(IDL.Nat, Result_17)),
    'blackholeVersion' : IDL.Nat,
  });
  const NotificationChannel__1 = IDL.Variant({ 'email' : IDL.Null });
  const CustomerNotificationSettings__2 = IDL.Record({
    'notifyOnCyclesApprovalBalanceBelow' : IDL.Opt(IDL.Nat),
    'channels' : IDL.Vec(NotificationChannel__1),
    'notifyOnICPBelow' : IDL.Opt(Tokens),
    'notifyOnTopupSuccess' : IDL.Bool,
    'notifyOnMemoryThresholdReached' : IDL.Bool,
    'notifyOnTopupFailure' : IDL.Bool,
  });
  const CustomerMetadata__2 = IDL.Record({
    'username' : IDL.Text,
    'displayName' : IDL.Opt(IDL.Text),
    'description' : IDL.Opt(IDL.Text),
    'website' : IDL.Opt(IDL.Text),
    'logoUrl' : IDL.Opt(IDL.Text),
  });
  const CustomerEmail__1 = IDL.Record({
    'verified' : IDL.Bool,
    'verificationCode' : IDL.Opt(VerificationCode),
    'address' : Email,
  });
  const AccountTypeShared__1 = IDL.Variant({
    'team' : IDL.Record({
      'teamType' : IDL.Variant({
        'standard_team' : IDL.Null,
        'sns_team' : IDL.Null,
      }),
      'members' : IDL.Vec(IDL.Tuple(IDL.Principal, Permission__1)),
    }),
    'individual' : IDL.Vec(IDL.Principal),
  });
  const CanisterId__1 = IDL.Principal;
  const TopupRule = IDL.Record({
    'method' : IDL.Variant({ 'by_amount' : Cycles, 'to_balance' : Cycles }),
    'threshold' : Cycles,
  });
  const MonitoringCanisterType = IDL.Variant({
    'nns' : IDL.Null,
    'sns' : IDL.Null,
    'publicStatus' : IDL.Null,
    'blackhole' : IDL.Null,
  });
  const CanisterConfig = IDL.Record({
    'id' : CanisterId__1,
    'topupRule' : TopupRule,
    'name' : IDL.Text,
    'memoryThreshold' : IDL.Opt(IDL.Nat),
    'createdTimestamp' : IDL.Opt(IDL.Nat),
    'monitoringType' : MonitoringCanisterType,
  });
  const PaymentConfigurationShared__2 = IDL.Record({
    'paymentMethod' : PaymentMethod,
    'cyclesAccount' : IDL.Opt(
      IDL.Record({
        'approvalAmount' : IDL.Opt(IDL.Nat),
        'walletProvider' : IDL.Variant({
          'plug' : IDL.Null,
          'external' : IDL.Null,
        }),
        'account' : Account,
      })
    ),
  });
  const CustomerRecordShared__1 = IDL.Record({
    'customerNotifications' : CustomerNotificationSettings__2,
    'metadata' : CustomerMetadata__2,
    'email' : IDL.Opt(CustomerEmail__1),
    'accountType' : AccountTypeShared__1,
    'onboardingCompletedTimestamp' : IDL.Opt(IDL.Nat),
    'canisters' : IDL.Vec(IDL.Tuple(IDL.Principal, CanisterConfig)),
    'createdTimestamp' : IDL.Opt(IDL.Nat),
    'customerId' : IDL.Principal,
    'paymentConfiguration' : PaymentConfigurationShared__2,
  });
  const CustomerNotificationSettings__1 = IDL.Record({
    'notifyOnCyclesApprovalBalanceBelow' : IDL.Opt(IDL.Nat),
    'channels' : IDL.Vec(NotificationChannel__1),
    'notifyOnICPBelow' : IDL.Opt(Tokens),
    'notifyOnTopupSuccess' : IDL.Bool,
    'notifyOnMemoryThresholdReached' : IDL.Bool,
    'notifyOnTopupFailure' : IDL.Bool,
  });
  const CustomerMetadata__1 = IDL.Record({
    'username' : IDL.Text,
    'displayName' : IDL.Opt(IDL.Text),
    'description' : IDL.Opt(IDL.Text),
    'website' : IDL.Opt(IDL.Text),
    'logoUrl' : IDL.Opt(IDL.Text),
  });
  const CustomerEmail = IDL.Record({
    'verified' : IDL.Bool,
    'verificationCode' : IDL.Opt(VerificationCode),
    'address' : Email,
  });
  const AccountTypeShared = IDL.Variant({
    'team' : IDL.Record({
      'teamType' : IDL.Variant({
        'standard_team' : IDL.Null,
        'sns_team' : IDL.Null,
      }),
      'members' : IDL.Vec(IDL.Tuple(IDL.Principal, Permission__1)),
    }),
    'individual' : IDL.Vec(IDL.Principal),
  });
  const CanisterId__4 = IDL.Principal;
  const TopupRule__2 = IDL.Record({
    'method' : IDL.Variant({ 'by_amount' : Cycles, 'to_balance' : Cycles }),
    'threshold' : Cycles,
  });
  const MonitoringCanisterType__2 = IDL.Variant({
    'nns' : IDL.Null,
    'sns' : IDL.Null,
    'publicStatus' : IDL.Null,
    'blackhole' : Version,
  });
  const CanisterConfig__2 = IDL.Record({
    'id' : CanisterId__4,
    'topupRule' : TopupRule__2,
    'name' : IDL.Text,
    'memoryThreshold' : IDL.Opt(IDL.Nat),
    'createdTimestamp' : IDL.Opt(IDL.Nat),
    'monitoringType' : MonitoringCanisterType__2,
  });
  const PaymentConfigurationShared__1 = IDL.Record({
    'paymentMethod' : PaymentMethod,
    'cyclesAccount' : IDL.Opt(
      IDL.Record({
        'approvalAmount' : IDL.Opt(IDL.Nat),
        'walletProvider' : IDL.Variant({
          'plug' : IDL.Null,
          'external' : IDL.Null,
        }),
        'account' : Account,
      })
    ),
  });
  const CustomerRecordShared = IDL.Record({
    'customerNotifications' : CustomerNotificationSettings__1,
    'metadata' : CustomerMetadata__1,
    'email' : IDL.Opt(CustomerEmail),
    'accountType' : AccountTypeShared,
    'onboardingCompletedTimestamp' : IDL.Opt(IDL.Nat),
    'canisters' : IDL.Vec(IDL.Tuple(IDL.Principal, CanisterConfig__2)),
    'createdTimestamp' : IDL.Opt(IDL.Nat),
    'customerId' : IDL.Principal,
    'paymentConfiguration' : PaymentConfigurationShared__1,
  });
  const Result_18 = IDL.Variant({
    'ok' : IDL.Vec(IDL.Principal),
    'err' : IDL.Text,
  });
  const AsTeamPrincipal = IDL.Record({ 'asTeamPrincipal' : IDL.Opt(TeamID) });
  const DefiniteCanisterSettings = IDL.Record({
    'freezing_threshold' : IDL.Opt(IDL.Nat),
    'controllers' : IDL.Vec(IDL.Principal),
    'reserved_cycles_limit' : IDL.Opt(IDL.Nat),
    'memory_allocation' : IDL.Opt(IDL.Nat),
    'compute_allocation' : IDL.Opt(IDL.Nat),
  });
  const SharedCanisterStatus = IDL.Record({
    'status' : IDL.Opt(
      IDL.Variant({
        'stopped' : IDL.Null,
        'stopping' : IDL.Null,
        'running' : IDL.Null,
      })
    ),
    'memory_size' : IDL.Opt(IDL.Nat),
    'cycles' : IDL.Nat,
    'settings' : IDL.Opt(DefiniteCanisterSettings),
    'idle_cycles_burned_per_day' : IDL.Opt(IDL.Nat),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'reserved_cycles' : IDL.Opt(IDL.Nat),
  });
  const Result_16 = IDL.Variant({
    'ok' : SharedCanisterStatus,
    'err' : IDL.Text,
  });
  const SharedCanisterStatusHistory = IDL.Vec(IDL.Tuple(IDL.Nat, Result_16));
  const CustomerMetadataAndId = IDL.Record({
    'id' : IDL.Principal,
    'metadata' : CustomerMetadata,
  });
  const Result_15 = IDL.Variant({
    'ok' : CustomerMetadataAndId,
    'err' : IDL.Text,
  });
  const Result_14 = IDL.Variant({
    'ok' : CustomerNotificationSettings,
    'err' : IDL.Null,
  });
  const Result_13 = IDL.Variant({
    'ok' : PaymentConfigurationShared,
    'err' : IDL.Null,
  });
  const Allowance = IDL.Record({
    'allowance' : IDL.Nat,
    'expires_at' : IDL.Opt(IDL.Nat64),
  });
  const ApprovalStatus = IDL.Record({
    'balance' : IDL.Nat,
    'allowance' : Allowance,
  });
  const ApprovalError = IDL.Variant({
    'InsufficientAllowance' : ApprovalStatus,
    'Unauthorized' : IDL.Null,
    'InsufficentBalance' : ApprovalStatus,
    'InvalidCustomer' : IDL.Null,
    'ImproperPaymentMethod' : IDL.Null,
  });
  const Result_12 = IDL.Variant({
    'ok' : ApprovalStatus,
    'err' : ApprovalError,
  });
  const PublicCanisterStatusWithOptionals = IDL.Record({
    'status' : IDL.Variant({
      'stopped' : IDL.Null,
      'stopping' : IDL.Null,
      'running' : IDL.Null,
    }),
    'memory_size' : IDL.Nat,
    'cycles' : IDL.Nat,
    'settings' : DefiniteCanisterSettings,
    'idle_cycles_burned_per_day' : IDL.Opt(IDL.Nat),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'reserved_cycles' : IDL.Opt(IDL.Nat),
  });
  const Result_11 = IDL.Variant({
    'ok' : PublicCanisterStatusWithOptionals,
    'err' : IDL.Text,
  });
  const SNSCanisterType = IDL.Variant({
    'dapp' : IDL.Null,
    'root' : IDL.Null,
    'swap' : IDL.Null,
    'ledger' : IDL.Null,
    'archive' : IDL.Null,
    'index' : IDL.Null,
    'governance' : IDL.Null,
  });
  const SNSCanisterMetadata = IDL.Record({
    'canisterType' : SNSCanisterType,
    'rootCanisterId' : IDL.Principal,
  });
  const SNSMetadata = IDL.Record({
    'url' : IDL.Opt(IDL.Text),
    'logo' : IDL.Opt(IDL.Text),
    'name' : IDL.Opt(IDL.Text),
    'description' : IDL.Opt(IDL.Text),
  });
  const SNSCanisters = IDL.Record({
    'root' : IDL.Opt(IDL.Principal),
    'swap' : IDL.Opt(IDL.Principal),
    'ledger' : IDL.Opt(IDL.Principal),
    'index' : IDL.Opt(IDL.Principal),
    'governance' : IDL.Opt(IDL.Principal),
    'dapps' : IDL.Vec(IDL.Principal),
    'archives' : IDL.Vec(IDL.Principal),
  });
  const Followee = IDL.Record({ 'id' : IDL.Vec(IDL.Nat8) });
  const Investor = IDL.Record({
    'CommunityFund' : IDL.Record({
      'hotkey_principal' : IDL.Text,
      'nns_neuron_id' : IDL.Nat64,
    }),
    'Direct' : IDL.Record({ 'buyer_principal' : IDL.Text }),
  });
  const NeuronRecipe = IDL.Record({
    'sns' : IDL.Opt(
      IDL.Record({
        'transfer_fee_paid_e8s' : IDL.Opt(IDL.Nat64),
        'transfer_start_timestamp_seconds' : IDL.Nat64,
        'amount_e8s' : IDL.Nat64,
        'amount_transferred_e8s' : IDL.Opt(IDL.Nat64),
        'transfer_success_timestamp_seconds' : IDL.Nat64,
      })
    ),
    'claimed_status' : IDL.Opt(IDL.Int32),
    'neuron_attributes' : IDL.Opt(
      IDL.Record({
        'dissolve_delay_seconds' : IDL.Nat64,
        'memo' : IDL.Nat64,
        'followees' : IDL.Vec(Followee),
      })
    ),
    'investor' : IDL.Opt(Investor),
  });
  const CFParticipant = IDL.Record({
    'hotkey_principal' : IDL.Text,
    'cf_neurons' : IDL.Vec(
      IDL.Record({ 'nns_neuron_id' : IDL.Nat64, 'amount_icp_e8s' : IDL.Nat64 })
    ),
  });
  const Buyer = IDL.Record({
    'icp' : IDL.Opt(
      IDL.Record({
        'transfer_fee_paid_e8s' : IDL.Opt(IDL.Nat64),
        'transfer_start_timestamp_seconds' : IDL.Nat64,
        'amount_e8s' : IDL.Nat64,
        'amount_transferred_e8s' : IDL.Opt(IDL.Nat64),
        'transfer_success_timestamp_seconds' : IDL.Nat64,
      })
    ),
    'text' : IDL.Text,
  });
  const GetStateResponse = IDL.Record({
    'swap' : IDL.Opt(
      IDL.Record({
        'neuron_recipes' : IDL.Vec(NeuronRecipe),
        'next_ticket_id' : IDL.Opt(IDL.Nat64),
        'decentralization_sale_open_timestamp_seconds' : IDL.Opt(IDL.Nat64),
        'finalize_swap_in_progress' : IDL.Opt(IDL.Bool),
        'cf_participants' : IDL.Vec(CFParticipant),
        'init' : IDL.Opt(
          IDL.Record({
            'sns_root_canister_id' : IDL.Text,
            'fallback_controller_principal_ids' : IDL.Vec(IDL.Text),
            'neuron_minimum_stake_e8s' : IDL.Opt(IDL.Nat64),
            'confirmation_text' : IDL.Opt(IDL.Text),
            'nns_governance_canister_id' : IDL.Text,
            'transaction_fee_e8s' : IDL.Opt(IDL.Nat64),
            'icp_ledger_canister_id' : IDL.Text,
            'sns_ledger_canister_id' : IDL.Text,
            'sns_governance_canister_id' : IDL.Text,
            'restricted_countries' : IDL.Opt(
              IDL.Record({ 'iso_codes' : IDL.Vec(IDL.Text) })
            ),
          })
        ),
        'purge_old_tickets_last_completion_timestamp_nanoseconds' : IDL.Opt(
          IDL.Nat64
        ),
        'lifecycle' : IDL.Int32,
        'purge_old_tickets_next_principal' : IDL.Opt(IDL.Vec(IDL.Nat8)),
        'buyers' : IDL.Vec(Buyer),
        'params' : IDL.Opt(
          IDL.Record({
            'min_participant_icp_e8s' : IDL.Nat64,
            'neuron_basket_construction_parameters' : IDL.Opt(
              IDL.Record({
                'dissolve_delay_interval_seconds' : IDL.Nat64,
                'count' : IDL.Nat64,
              })
            ),
            'max_icp_e8s' : IDL.Nat64,
            'swap_due_timestamp_seconds' : IDL.Nat64,
            'min_participants' : IDL.Nat32,
            'sns_token_e8s' : IDL.Nat64,
            'sale_delay_seconds' : IDL.Opt(IDL.Nat64),
            'max_participant_icp_e8s' : IDL.Nat64,
            'min_icp_e8s' : IDL.Nat64,
          })
        ),
        'open_sns_token_swap_proposal_id' : IDL.Opt(IDL.Nat64),
      })
    ),
  });
  const SNSConfiguration = IDL.Record({
    'metadata' : SNSMetadata,
    'canisters' : SNSCanisters,
    'swapState' : GetStateResponse,
  });
  const Subnet = IDL.Record({
    'id' : SubnetId,
    'requiresCanisterPresence' : IDL.Bool,
    'specialization' : IDL.Opt(IDL.Text),
    'subnetType' : SubnetType,
  });
  const SubnetDataShared = IDL.Record({
    'canisterFactoryOutpostMap' : IDL.Vec(IDL.Tuple(SubnetId, CanisterId)),
    'metadataMap' : IDL.Vec(IDL.Tuple(SubnetId, Subnet)),
  });
  const ErrorCode = IDL.Variant({
    'canister_error' : IDL.Null,
    'call_error' : IDL.Record({ 'err_code' : IDL.Nat32 }),
    'system_transient' : IDL.Null,
    'future' : IDL.Nat32,
    'canister_reject' : IDL.Null,
    'destination_invalid' : IDL.Null,
    'system_fatal' : IDL.Null,
  });
  const TopupExecutionError = IDL.Record({
    'errorMessage' : IDL.Text,
    'errorCode' : ErrorCode,
    'timestamp' : IDL.Nat,
  });
  const TopupExecutionCheckpoint = IDL.Variant({
    'account_balances_settled' : IDL.Null,
    'started' : IDL.Null,
    'status_checks_completed' : IDL.Null,
    'completed' : IDL.Null,
    'account_balances_locked' : IDL.Null,
    'cycles_sent' : IDL.Null,
  });
  const TopupExecutionState = IDL.Record({
    'state' : TopupExecutionCheckpoint,
    'timestamp' : IDL.Nat,
  });
  const CycleOpsHealthStats = IDL.Record({
    'heapSize' : IDL.Nat,
    'cycleBalance' : IDL.Nat,
    'locked' : IDL.Bool,
    'canisterCount' : IDL.Nat,
    'topupExecutionErrors' : IDL.Vec(TopupExecutionError),
    'topupExecutionState' : TopupExecutionState,
  });
  const Result_10 = IDL.Variant({
    'ok' : CycleOpsHealthStats,
    'err' : IDL.Text,
  });
  const Result_9 = IDL.Variant({ 'ok' : Tokens, 'err' : IDL.Text });
  const PullLatestCustomerTransactionsResult = IDL.Record({
    'timestampInNS' : IDL.Int,
    'isQueueEmpty' : IDL.Bool,
    'customerTransactions' : IDL.Vec(CustomerTransaction),
  });
  const PullLatestNotificationsResult = IDL.Record({
    'timestampInNS' : IDL.Nat,
    'notifications' : IDL.Vec(CustomerNotificationMessage),
    'isNotificationQueueEmpty' : IDL.Bool,
  });
  const PulledCustomerAllowance = IDL.Record({
    'allowanceResult' : ApprovalStatus,
    'customerId' : IDL.Principal,
  });
  const WalletProvider = IDL.Variant({
    'plug' : IDL.Null,
    'external' : IDL.Null,
  });
  const SNSRootCanisterId = IDL.Principal;
  const Balance = IDL.Record({
    'e8s' : IDL.Nat64,
    'permanentlyLocked' : IDL.Nat64,
    'temporarilyLocked' : IDL.Nat64,
  });
  const AccountIdentifier = IDL.Vec(IDL.Nat8);
  const ICRC1Account = IDL.Record({
    'owner' : IDL.Principal,
    'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
  });
  const BlockIndex = IDL.Nat64;
  const TransferError__1 = IDL.Variant({
    'TxTooOld' : IDL.Record({ 'allowed_window_nanos' : IDL.Nat64 }),
    'BadFee' : IDL.Record({ 'expected_fee' : Tokens }),
    'TxDuplicate' : IDL.Record({ 'duplicate_of' : BlockIndex }),
    'TxCreatedInFuture' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : Tokens }),
  });
  const TransferResult = IDL.Variant({
    'Ok' : BlockIndex,
    'Err' : TransferError__1,
  });
  const CustomerWithdrawResult = IDL.Variant({
    'ok' : TransferResult,
    'err' : IDL.Text,
  });
  const Permission = IDL.Variant({
    'admin' : IDL.Null,
    'read' : IDL.Null,
    'write' : IDL.Null,
  });
  const TeamMemberMetadata = IDL.Record({
    'permission' : Permission,
    'metadata' : CustomerMetadata,
    'customerId' : IDL.Principal,
  });
  const Result_7 = IDL.Variant({
    'ok' : IDL.Vec(TeamMemberMetadata),
    'err' : IDL.Text,
  });
  const Result_6 = IDL.Variant({
    'ok' : IDL.Vec(IDL.Tuple(IDL.Principal, Permission)),
    'err' : IDL.Text,
  });
  const FinalizedCustomerTransaction = IDL.Record({
    'transaction' : TransactionType,
    'timestamp' : IDL.Nat,
    'customerId' : IDL.Principal,
    'transactionId' : IDL.Text,
  });
  const GetTransactionsResult = IDL.Record({
    'results' : IDL.Vec(FinalizedCustomerTransaction),
    'nextKey' : IDL.Opt(IDL.Text),
  });
  const Result_5 = IDL.Variant({
    'ok' : GetTransactionsResult,
    'err' : IDL.Text,
  });
  const NewTeamResponse = IDL.Record({
    'metadata' : CustomerMetadata,
    'teamID' : TeamID,
  });
  const Result_4 = IDL.Variant({ 'ok' : NewTeamResponse, 'err' : IDL.Text });
  const TransferFromError = IDL.Variant({
    'GenericError' : IDL.Record({
      'message' : IDL.Text,
      'error_code' : IDL.Nat,
    }),
    'TemporarilyUnavailable' : IDL.Null,
    'InsufficientAllowance' : IDL.Record({ 'allowance' : IDL.Nat }),
    'BadBurn' : IDL.Record({ 'min_burn_amount' : IDL.Nat }),
    'Duplicate' : IDL.Record({ 'duplicate_of' : IDL.Nat }),
    'BadFee' : IDL.Record({ 'expected_fee' : IDL.Nat }),
    'CreatedInFuture' : IDL.Record({ 'ledger_time' : IDL.Nat64 }),
    'TooOld' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : IDL.Nat }),
  });
  const Result_3 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : TransferFromError });
  const TransferError = IDL.Variant({
    'GenericError' : IDL.Record({
      'message' : IDL.Text,
      'error_code' : IDL.Nat,
    }),
    'TemporarilyUnavailable' : IDL.Null,
    'BadBurn' : IDL.Record({ 'min_burn_amount' : IDL.Nat }),
    'Duplicate' : IDL.Record({ 'duplicate_of' : IDL.Nat }),
    'BadFee' : IDL.Record({ 'expected_fee' : IDL.Nat }),
    'CreatedInFuture' : IDL.Record({ 'ledger_time' : IDL.Nat64 }),
    'TooOld' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : IDL.Nat }),
  });
  const Result_2 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : TransferError });
  const UpdateCMCMetricsArgs = IDL.Record({
    'timestampInMs' : IDL.Nat,
    'cmc_cycles_limit' : IDL.Nat,
    'cmc_cycles_minted_total' : IDL.Nat,
    'cmc_icp_xdr_conversion_rate' : IDL.Opt(IDL.Float64),
    'cmc_limiter_cycles' : IDL.Nat,
  });
  const VerifyCanisterResult = IDL.Variant({
    'err' : IDL.Variant({
      'other' : IDL.Text,
      'already_added_not_verified' : IDL.Null,
      'already_added_and_verified' : IDL.Null,
      'invalid_request' : IDL.Text,
    }),
    'verified' : IDL.Null,
    'notVerified' : IDL.Null,
  });
  const VerifyCanisterResultVersioned = IDL.Variant({
    'err' : IDL.Variant({
      'other' : IDL.Text,
      'canister_not_yet_added' : IDL.Null,
      'already_added_not_verified' : IDL.Null,
      'already_added_and_verified' : IDL.Null,
      'invalid_request' : IDL.Text,
    }),
    'verified' : IDL.Null,
    'notVerified' : IDL.Null,
  });
  const VerifyCycleLedgerPrincipalArgs = IDL.Record({
    'cycleOpsCustomerPrincipal' : IDL.Principal,
  });
  const RejectionCode = IDL.Variant({
    'NoError' : IDL.Null,
    'CanisterError' : IDL.Null,
    'SysTransient' : IDL.Null,
    'DestinationInvalid' : IDL.Null,
    'Unknown' : IDL.Null,
    'SysFatal' : IDL.Null,
    'CanisterReject' : IDL.Null,
  });
  const WithdrawError = IDL.Variant({
    'FailedToWithdraw' : IDL.Record({
      'rejection_code' : RejectionCode,
      'fee_block' : IDL.Opt(IDL.Nat),
      'rejection_reason' : IDL.Text,
    }),
    'GenericError' : IDL.Record({
      'message' : IDL.Text,
      'error_code' : IDL.Nat,
    }),
    'TemporarilyUnavailable' : IDL.Null,
    'Duplicate' : IDL.Record({ 'duplicate_of' : IDL.Nat }),
    'BadFee' : IDL.Record({ 'expected_fee' : IDL.Nat }),
    'InvalidReceiver' : IDL.Record({ 'receiver' : IDL.Principal }),
    'CreatedInFuture' : IDL.Record({ 'ledger_time' : IDL.Nat64 }),
    'TooOld' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : IDL.Nat }),
  });
  const Result = IDL.Variant({ 'ok' : IDL.Nat, 'err' : WithdrawError });
  const CycleOpsService = IDL.Service({
    'accountsCanisterHealthCheck' : IDL.Func([], [Result_24], []),
    'addAdmin' : IDL.Func([IDL.Principal], [], []),
    'addCanister' : IDL.Func(
        [
          IDL.Record({
            'topupRule' : IDL.Opt(TopupRule__1),
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'name' : IDL.Opt(IDL.Text),
            'canisterId' : IDL.Principal,
          }),
        ],
        [AddCanisterResult],
        [],
      ),
    'addCanisterVersioned' : IDL.Func(
        [
          IDL.Record({
            'topupRule' : IDL.Opt(TopupRule__1),
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'name' : IDL.Opt(IDL.Text),
            'blackholeVersion' : IDL.Opt(IDL.Nat),
            'canisterId' : IDL.Principal,
          }),
        ],
        [AddCanisterResultVersioned],
        [],
      ),
    'addCyclesPurchaseDetail' : IDL.Func(
        [CMCPurchase],
        [CyclesPurchaseDetail],
        [],
      ),
    'addNNSMonitoredCanister' : IDL.Func(
        [
          IDL.Record({
            'topupRule' : IDL.Opt(TopupRule__1),
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'name' : IDL.Opt(IDL.Text),
            'canisterId' : IDL.Principal,
          }),
        ],
        [AddNNSCanisterResult],
        [],
      ),
    'addNewLimitedSubnetCanisterHub' : IDL.Func(
        [AddNewLimitedSubnetCanisterHubArgs],
        [Result_1],
        [],
      ),
    'addSNSMonitoredCanistersBatch' : IDL.Func(
        [
          IDL.Record({
            'topupRule' : IDL.Opt(TopupRule__1),
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'snsRootCanisterId' : IDL.Principal,
            'snsCanistersToMonitor' : IDL.Vec(IDL.Principal),
          }),
        ],
        [AddSNSMonitoredCanistersResult],
        [],
      ),
    'addVerificationEmailToken' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [Result_1],
        [],
      ),
    'admin_depositCyclesFloatToTeamLedger' : IDL.Func(
        [IDL.Record({ 'depositCyclesAmount' : IDL.Nat })],
        [Result_23],
        [],
      ),
    'admin_dumpDataExceptCanisterStateV9' : IDL.Func(
        [],
        [
          IDL.Record({
            'recentTransactionQueue' : IDL.Vec(CustomerTransaction),
            'customerSearchIndices' : IDL.Record({
              'uniqueUsernameToCustomerId' : IDL.Vec(
                IDL.Tuple(IDL.Text, IDL.Principal)
              ),
            }),
            'perAccountCanisterSearchMetadata' : IDL.Vec(
              PerAccountSharedCanisterTagSearchBiMap
            ),
            'customerMapV9' : IDL.Vec(CustomerRecordShared__2),
            'notificationQueue' : IDL.Vec(CustomerNotificationMessage),
            'verifiedCycleLedgerAccountToCustomersMap' : IDL.Vec(
              IDL.Tuple(IDL.Principal, IDL.Vec(IDL.Principal))
            ),
            'projectMap' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Vec(Project))),
          }),
        ],
        ['query'],
      ),
    'admin_getCMCMetrics' : IDL.Func([], [CMCMetrics], ['query']),
    'admin_getCanisterHistoryForCustomerPaginated' : IDL.Func(
        [
          IDL.Record({
            'customer' : IDL.Principal,
            'startKey' : IDL.Opt(IDL.Principal),
          }),
        ],
        [PaginatedCustomerCanisters],
        ['query'],
      ),
    'admin_getCanisterStatusWithBlackholeV0' : IDL.Func(
        [IDL.Record({ 'canisterId' : IDL.Principal })],
        [Result_22],
        [],
      ),
    'admin_getCustomerCanisterPages' : IDL.Func(
        [IDL.Principal],
        [IDL.Vec(IDL.Principal)],
        ['query'],
      ),
    'admin_getCyclesPurchaseHistory' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Nat, CyclesPurchaseDetail))],
        ['query'],
      ),
    'admin_getSelfTopupThreshold' : IDL.Func([], [IDL.Nat], ['query']),
    'admin_performSelfTopupFromCyclesLedger' : IDL.Func([], [Result_8], []),
    'admin_setSelfTopupThreshold' : IDL.Func([IDL.Nat], [Result_1], []),
    'batchCustomerUpdateCanisterMemoryThreshold' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterMemoryThresholds' : IDL.Vec(
              BatchUpdateCanisterMemoryThreshold
            ),
          }),
        ],
        [IDL.Vec(BatchUpdateCanisterMemoryThresholdResult)],
        [],
      ),
    'batchCustomerUpdateCanisterReservedCyclesThreshold' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterReservedCyclesThresholds' : IDL.Vec(
              BatchUpdateReservedCyclesThreshold
            ),
          }),
        ],
        [IDL.Vec(BatchUpdateReservedCyclesThresholdResult)],
        [],
      ),
    'batchCustomerUpdateTopupRule' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterTopupRules' : IDL.Vec(BatchUpdateTopupRule),
          }),
        ],
        [IDL.Vec(BatchUpdateTopupRuleResult)],
        [],
      ),
    'batchDeleteCanisters' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterIds' : IDL.Vec(IDL.Principal),
          }),
        ],
        [IDL.Vec(BatchDeleteCanisterResult)],
        [],
      ),
    'batchManualTopups' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterTopups' : IDL.Vec(CanisterTopup),
          }),
        ],
        [Result_1],
        [],
      ),
    'batchUpdateCanisterProjects' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterProjectUpdates' : IDL.Vec(BatchUpdateCanisterProjects),
          }),
        ],
        [IDL.Vec(BatchUpdateCanisterProjectsResult)],
        [],
      ),
    'batchUpdateCanisterSearchMetadata' : IDL.Func(
        [
          IDL.Record({
            'canisterSearchMetadatas' : IDL.Vec(
              BatchUpdateCanisterSearchMetadata
            ),
            'asTeamPrincipal' : IDL.Opt(TeamID),
          }),
        ],
        [IDL.Vec(BatchUpdateCanisterSearchMetadataResult)],
        [],
      ),
    'batchUpdateCanisterTags' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterTagUpdates' : IDL.Vec(BatchUpdateCanisterTags),
          }),
        ],
        [IDL.Vec(BatchUpdateCanisterTagsResult)],
        [],
      ),
    'checkAdmin' : IDL.Func([], [IDL.Bool], ['query']),
    'completeEmailVerification' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [Result_1],
        [],
      ),
    'configCheck' : IDL.Func([], [Result_1], []),
    'createCanister' : IDL.Func(
        [
          IDL.Record({
            'subnetSelection' : IDL.Opt(SubnetSelection),
            'topupRule' : IDL.Opt(TopupRule__1),
            'controllers' : IDL.Vec(IDL.Principal),
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'name' : IDL.Opt(IDL.Text),
            'withStartingCyclesBalance' : IDL.Nat,
          }),
        ],
        [Result_20],
        [],
      ),
    'createCustomerRecord' : IDL.Func(
        [IDL.Record({ 'metadata' : CustomerMetadata })],
        [Result_21],
        [],
      ),
    'createProject' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'projectName' : IDL.Text,
          }),
        ],
        [Result_1],
        [],
      ),
    'createStatusChecker' : IDL.Func([], [Result_20], []),
    'createStatusCheckerFromWasm' : IDL.Func(
        [IDL.Vec(IDL.Nat8)],
        [Result_20],
        [],
      ),
    'createStatusCheckerV2' : IDL.Func([], [Result_20], []),
    'createStatusCheckerV2FromWasm' : IDL.Func(
        [IDL.Vec(IDL.Nat8)],
        [Result_20],
        [],
      ),
    'customerUpdateCanisterMemoryThreshold' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'memoryThreshold' : IDL.Opt(IDL.Nat),
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'customerUpdateCanisterName' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'name' : IDL.Text,
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'customerUpdateCanisterReservedCyclesThreshold' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'reservedCyclesPercentageThreshold' : IDL.Opt(IDL.Nat),
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'customerUpdateTopUpRule' : IDL.Func(
        [
          IDL.Record({
            'topupRule' : TopupRule__1,
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'deleteCanister' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'deleteProject' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'projectName' : IDL.Text,
          }),
        ],
        [Result_1],
        [],
      ),
    'dump' : IDL.Func(
        [],
        [
          IDL.Record({
            'recentTransactionQueue' : IDL.Vec(CustomerTransaction),
            'publicCanisterState' : IDL.Vec(
              IDL.Tuple(IDL.Principal, FrontendFriendlyPublicCanisterStateV3)
            ),
            'customerMapV9' : IDL.Vec(CustomerRecordShared__2),
            'notificationQueue' : IDL.Vec(CustomerNotificationMessage),
            'verifiedGlobalCanisterStateV3' : IDL.Vec(
              IDL.Tuple(IDL.Principal, FrontendFriendlyCanisterStateV3)
            ),
          }),
        ],
        ['query'],
      ),
    'dumpDataExceptCanisterStateV7' : IDL.Func(
        [],
        [
          IDL.Record({
            'recentTransactionQueue' : IDL.Vec(CustomerTransaction),
            'customerSearchIndices' : IDL.Record({
              'uniqueUsernameToCustomerId' : IDL.Vec(
                IDL.Tuple(IDL.Text, IDL.Principal)
              ),
            }),
            'perAccountCanisterSearchMetadata' : IDL.Vec(
              PerAccountSharedCanisterTagSearchBiMap
            ),
            'customerMapV7' : IDL.Vec(CustomerRecordShared__1),
            'notificationQueue' : IDL.Vec(CustomerNotificationMessage),
            'verifiedCycleLedgerAccountToCustomersMap' : IDL.Vec(
              IDL.Tuple(IDL.Principal, IDL.Vec(IDL.Principal))
            ),
            'projectMap' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Vec(Project))),
          }),
        ],
        ['query'],
      ),
    'dumpDataExceptCanisterStateV8' : IDL.Func(
        [],
        [
          IDL.Record({
            'recentTransactionQueue' : IDL.Vec(CustomerTransaction),
            'customerSearchIndices' : IDL.Record({
              'uniqueUsernameToCustomerId' : IDL.Vec(
                IDL.Tuple(IDL.Text, IDL.Principal)
              ),
            }),
            'perAccountCanisterSearchMetadata' : IDL.Vec(
              PerAccountSharedCanisterTagSearchBiMap
            ),
            'customerMapV8' : IDL.Vec(CustomerRecordShared),
            'notificationQueue' : IDL.Vec(CustomerNotificationMessage),
            'verifiedCycleLedgerAccountToCustomersMap' : IDL.Vec(
              IDL.Tuple(IDL.Principal, IDL.Vec(IDL.Principal))
            ),
            'projectMap' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Vec(Project))),
          }),
        ],
        ['query'],
      ),
    'dumpPaginatedPublicCanisterStateV3' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [
          IDL.Record({
            'results' : IDL.Vec(
              IDL.Tuple(IDL.Principal, FrontendFriendlyPublicCanisterStateV3)
            ),
            'nextKey' : IDL.Opt(IDL.Principal),
          }),
        ],
        ['query'],
      ),
    'dumpPaginatedVerifiedCanisterState' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [
          IDL.Record({
            'results' : IDL.Vec(
              IDL.Tuple(IDL.Principal, FrontendFriendlyCanisterStateV3)
            ),
            'nextKey' : IDL.Opt(IDL.Principal),
          }),
        ],
        ['query'],
      ),
    'dumpPaginatedVerifiedCanisterStateV3' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [
          IDL.Record({
            'results' : IDL.Vec(
              IDL.Tuple(IDL.Principal, FrontendFriendlyCanisterStateV3)
            ),
            'nextKey' : IDL.Opt(IDL.Principal),
          }),
        ],
        ['query'],
      ),
    'dumpPublicCanisterStateV3' : IDL.Func(
        [],
        [
          IDL.Vec(
            IDL.Tuple(IDL.Principal, FrontendFriendlyPublicCanisterStateV3)
          ),
        ],
        ['query'],
      ),
    'executeTopups' : IDL.Func([], [Result_1], []),
    'getAccountsCanisterPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getAdmins' : IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'getBatchSize' : IDL.Func([], [IDL.Nat], ['query']),
    'getCanisterControllers' : IDL.Func(
        [IDL.Record({ 'canisterId' : IDL.Principal })],
        [Result_18],
        [],
      ),
    'getCanisterCreationFeeForSubnet' : IDL.Func(
        [IDL.Record({ 'subnetId' : SubnetId })],
        [Result_8],
        ['query'],
      ),
    'getCanisterHistoryAndMetadataPaginated' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'startKey' : IDL.Opt(IDL.Principal),
          }),
        ],
        [PaginatedCustomerCanisters],
        ['query'],
      ),
    'getCanisterStatus' : IDL.Func([IDL.Principal], [Result_17], []),
    'getCanisters' : IDL.Func(
        [AsTeamPrincipal],
        [
          IDL.Vec(
            IDL.Tuple(
              IDL.Principal,
              CanisterConfig,
              SharedCanisterStatusHistory,
              IDL.Opt(ReturnableCanisterSearchMetadata),
              MonitoringCanisterType,
            )
          ),
        ],
        ['query'],
      ),
    'getCustomerById' : IDL.Func([IDL.Principal], [Result_15], ['query']),
    'getCustomerByUsername' : IDL.Func(
        [IDL.Record({ 'username' : IDL.Text, 'isSNSTeam' : IDL.Bool })],
        [Result_15],
        ['query'],
      ),
    'getCustomerCanisterPages' : IDL.Func(
        [AsTeamPrincipal],
        [IDL.Vec(IDL.Principal)],
        ['query'],
      ),
    'getCustomerEmail' : IDL.Func(
        [AsTeamPrincipal],
        [IDL.Opt(IDL.Record({ 'verified' : IDL.Bool, 'address' : IDL.Text }))],
        ['query'],
      ),
    'getCustomerNotificationSettings' : IDL.Func(
        [AsTeamPrincipal],
        [Result_14],
        ['query'],
      ),
    'getCustomerPaymentConfiguration' : IDL.Func(
        [AsTeamPrincipal],
        [Result_13],
        ['query'],
      ),
    'getCycleLedgerAllowance' : IDL.Func([AsTeamPrincipal], [Result_12], []),
    'getCycleLedgerPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getCyclesMargin' : IDL.Func([], [IDL.Float64], ['query']),
    'getLambdaPrincipal' : IDL.Func([], [IDL.Opt(IDL.Principal)], ['query']),
    'getMonitoringPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getNNSMonitoredCanisterStatus' : IDL.Func(
        [IDL.Principal],
        [Result_11],
        [],
      ),
    'getProjects' : IDL.Func([AsTeamPrincipal], [IDL.Vec(Project)], ['query']),
    'getRecentCustomerTransactions' : IDL.Func(
        [AsTeamPrincipal],
        [IDL.Vec(CustomerTransaction)],
        ['query'],
      ),
    'getRefreshAllowancesTimerCanisterPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getSNSCanisterIdToMetadataMap' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, SNSCanisterMetadata))],
        ['query'],
      ),
    'getSNSConfigurations' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, SNSConfiguration))],
        ['query'],
      ),
    'getStatusCheckerPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getStatusCheckerV2Principal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getSubnetData' : IDL.Func([], [SubnetDataShared], ['query']),
    'getTransactionHistoryCanisterPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'healthCheck' : IDL.Func([], [Result_10], ['query']),
    'isCycleLedgerAccountApprovedForCustomer' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'cycleLedgerAccountPrincipal' : IDL.Principal,
          }),
        ],
        [IDL.Bool],
        ['query'],
      ),
    'isEmailVerified' : IDL.Func([AsTeamPrincipal], [IDL.Bool], ['query']),
    'isOnboardingComplete' : IDL.Func([AsTeamPrincipal], [IDL.Bool], ['query']),
    'isRefreshAllowancesTimerAdmin' : IDL.Func([IDL.Principal], [IDL.Bool], []),
    'isSubnetMetadataTimerAdmin' : IDL.Func([IDL.Principal], [IDL.Bool], []),
    'isTopupTimerAdmin' : IDL.Func([IDL.Principal], [IDL.Bool], []),
    'lambdaHealthCheck' : IDL.Func([], [Result_1], ['query']),
    'manualTopup' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'topupAmount' : ManualTopupType,
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'pricePerTrillion' : IDL.Func([], [Result_9], ['query']),
    'professional_setLastStandardTopupTimestamp' : IDL.Func(
        [IDL.Nat],
        [Result_1],
        [],
      ),
    'professional_setTopupTimerCanisterPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_1],
        [],
      ),
    'pullLatestCustomerTransactions' : IDL.Func(
        [IDL.Nat],
        [PullLatestCustomerTransactionsResult],
        [],
      ),
    'pullLatestNotifications' : IDL.Func(
        [IDL.Nat],
        [PullLatestNotificationsResult],
        [],
      ),
    'refreshCyclesApprovalBalances' : IDL.Func(
        [IDL.Vec(PulledCustomerAllowance)],
        [],
        [],
      ),
    'removeAdmin' : IDL.Func([IDL.Principal], [IDL.Bool], []),
    'removeCycleLedgerAccountToCustomerMapping' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'cycleOpsCustomerPrincipal' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'renameProject' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'projectName' : IDL.Text,
            'newProjectName' : IDL.Text,
          }),
        ],
        [Result_1],
        [],
      ),
    'retrieveCyclesPaymentCustomers' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, Account))],
        [],
      ),
    'runCanisterStatusChecks' : IDL.Func([], [Result_1], []),
    'setAccountsCanisterPrincipal' : IDL.Func([IDL.Opt(IDL.Principal)], [], []),
    'setBatchSize' : IDL.Func([IDL.Nat], [Result_1], []),
    'setCustomerNotificationSettings' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'notifyOnCyclesApprovalBalanceBelow' : IDL.Opt(IDL.Nat),
            'channels' : IDL.Vec(NotificationChannel),
            'notifyOnICPBelow' : IDL.Opt(Tokens),
            'notifyOnTopupSuccess' : IDL.Bool,
            'notifyOnMemoryThresholdReached' : IDL.Bool,
            'notifyOnTopupFailure' : IDL.Bool,
            'notifyOnReservedCyclesThresholdReached' : IDL.Bool,
          }),
        ],
        [Result_1],
        [],
      ),
    'setCustomerPaymentConfiguration' : IDL.Func(
        [
          IDL.Record({
            'paymentMethod' : IDL.Variant({
              'icp' : IDL.Null,
              'cycles' : IDL.Record({
                'walletProvider' : WalletProvider,
                'account' : Account,
              }),
            }),
            'asTeamPrincipal' : IDL.Opt(TeamID),
          }),
        ],
        [Result_1],
        [],
      ),
    'setCycleLedgerPrincipal' : IDL.Func([IDL.Principal], [], []),
    'setLambdaPrincipal' : IDL.Func([IDL.Opt(IDL.Principal)], [], ['oneway']),
    'setMaxCycleLedgerBatch' : IDL.Func([IDL.Nat], [], ['oneway']),
    'setMonitoringPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [],
        ['oneway'],
      ),
    'setNNSStatusCheckerPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_1],
        [],
      ),
    'setOnboardingComplete' : IDL.Func([AsTeamPrincipal], [Result_1], []),
    'setRefreshAllowancesTimerCanisterPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [],
        [],
      ),
    'setSNSConfigCachePrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_1],
        [],
      ),
    'setSNSConfigurations' : IDL.Func(
        [IDL.Vec(IDL.Tuple(SNSRootCanisterId, SNSConfiguration))],
        [],
        [],
      ),
    'setSNSStatusCheckerPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_1],
        [],
      ),
    'setSubnetMetadataTimerCanisterPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_1],
        [],
      ),
    'setSubnetMetadatas' : IDL.Func([IDL.Vec(Subnet)], [], []),
    'setTopupTimerCanisterPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_1],
        [],
      ),
    'setTransactionHistoryCanisterPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [],
        [],
      ),
    'statusCheckerHealthCheck' : IDL.Func([], [Result_8], []),
    'teams_accountsBalance' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [Balance],
        [],
      ),
    'teams_accountsLocalAccount' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [AccountIdentifier],
        ['query'],
      ),
    'teams_accountsLocalAccountText' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [IDL.Text],
        ['query'],
      ),
    'teams_accountsLocalICRCAccount' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [ICRC1Account],
        ['query'],
      ),
    'teams_accountsLocalICRCAccountText' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [IDL.Text],
        ['query'],
      ),
    'teams_accountsWithdraw' : IDL.Func(
        [
          IDL.Record({
            'recipient' : IDL.Text,
            'teamID' : IDL.Principal,
            'amount' : Tokens,
          }),
        ],
        [CustomerWithdrawResult],
        [],
      ),
    'teams_addMember' : IDL.Func(
        [
          IDL.Record({
            'member' : IDL.Principal,
            'permission' : Permission,
            'teamID' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'teams_changePermission' : IDL.Func(
        [
          IDL.Record({
            'member' : IDL.Principal,
            'permission' : Permission,
            'teamID' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'teams_delete' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [Result_1],
        [],
      ),
    'teams_getTeamMembers' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [Result_7],
        ['query'],
      ),
    'teams_getTeamsCallerIsMemberOf' : IDL.Func([], [Result_6], ['query']),
    'teams_getTransactionHistory' : IDL.Func(
        [
          IDL.Record({
            'startKey' : IDL.Text,
            'limit' : IDL.Nat,
            'ascending' : IDL.Bool,
            'teamID' : IDL.Principal,
            'endKey' : IDL.Text,
          }),
        ],
        [Result_5],
        [],
      ),
    'teams_leaveTeam' : IDL.Func(
        [IDL.Record({ 'teamID' : IDL.Principal })],
        [Result_1],
        [],
      ),
    'teams_new' : IDL.Func(
        [IDL.Record({ 'teamMetadata' : CustomerMetadata })],
        [Result_4],
        [],
      ),
    'teams_removeMember' : IDL.Func(
        [IDL.Record({ 'member' : IDL.Principal, 'teamID' : IDL.Principal })],
        [Result_1],
        [],
      ),
    'transferCyclesFromCustomer' : IDL.Func(
        [
          IDL.Record({
            'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
            'customerId' : IDL.Principal,
            'amount' : IDL.Nat,
          }),
        ],
        [Result_3],
        [],
      ),
    'transferCyclesFromCustomerBatch' : IDL.Func(
        [
          IDL.Record({
            'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
            'targets' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat)),
          }),
        ],
        [IDL.Vec(Result_3)],
        [],
      ),
    'transferProject' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'projectName' : IDL.Text,
            'newProjectOwner' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'transferToCycleLedger' : IDL.Func(
        [
          IDL.Record({
            'to' : Account,
            'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
            'amount' : IDL.Nat,
          }),
        ],
        [Result_2],
        [],
      ),
    'updateCMCMetrics' : IDL.Func([UpdateCMCMetricsArgs], [], []),
    'updateCanisterProject' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'projectName' : IDL.Opt(IDL.Text),
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'updateCanisterSearchMetadata' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'projectName' : IDL.Opt(IDL.Text),
            'keywordTags' : IDL.Vec(IDL.Text),
            'isPublic' : IDL.Bool,
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'updateCanisterTags' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'keywordTags' : IDL.Vec(IDL.Text),
            'canisterId' : IDL.Principal,
          }),
        ],
        [Result_1],
        [],
      ),
    'updateCustomerEmail' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'email' : IDL.Text,
          }),
        ],
        [Result_1],
        [],
      ),
    'updateCustomerMetadata' : IDL.Func(
        [
          IDL.Record({
            'username' : IDL.Text,
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'displayName' : IDL.Opt(IDL.Text),
            'description' : IDL.Opt(IDL.Text),
            'website' : IDL.Opt(IDL.Text),
            'logoUrl' : IDL.Opt(IDL.Text),
          }),
        ],
        [Result_1],
        [],
      ),
    'verifyBlackholeAddedAsController' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'canisterId' : IDL.Principal,
          }),
        ],
        [VerifyCanisterResult],
        [],
      ),
    'verifyBlackholeAddedAsControllerVersioned' : IDL.Func(
        [
          IDL.Record({
            'asTeamPrincipal' : IDL.Opt(TeamID),
            'blackholeVersion' : IDL.Nat,
            'canisterId' : IDL.Principal,
          }),
        ],
        [VerifyCanisterResultVersioned],
        [],
      ),
    'verifyOwnershipOfCycleLedgerPrincipal' : IDL.Func(
        [VerifyCycleLedgerPrincipalArgs],
        [Result_1],
        [],
      ),
    'withdrawCyclesToCanister' : IDL.Func(
        [
          IDL.Record({
            'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
            'targetCanister' : IDL.Principal,
            'amount' : IDL.Nat,
          }),
        ],
        [Result],
        [],
      ),
    'withdrawCyclesToCanisterBatch' : IDL.Func(
        [
          IDL.Record({
            'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
            'targets' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat)),
          }),
        ],
        [IDL.Vec(Result)],
        [],
      ),
  });
  return CycleOpsService;
};
export const init = (context: { IDL: typeof IDL }) => { return [] as IDL.Type[]; };
